import ITestimonial from '../interfaces/models/testimonial.interface';

export const Testimonials: ITestimonial[] = [
 {
  id: 1,
  star: 5,
  description:
   'TestimonialsConstant/des-01',
  fullName: 'Milad Ardehali',
  image: 'https://cdn.pinkswan.ch/application/LOUISEBOT/23080707075554_unnamed.jpg',
  job: 'TestimonialsConstant/job-01',
 },
 {
  id: 2,
  star: 5,
  description: 'TestimonialsConstant/des-02',
  fullName: 'John',
  job: 'TestimonialsConstant/job-02',
  image: 'https://cdn.pinkswan.ch/application/LOUISEBOT/23080613503328_2.jpg',
 },
 {
  id: 3,
  star: 5,
  description: 'TestimonialsConstant/des-03',
  fullName: 'Sarah',
  job: 'TestimonialsConstant/job-03',
  image: 'https://cdn.pinkswan.ch/application/LOUISEBOT/23080613503321_3.jpg',
 },
 {
  id: 4,
  star: 5,
  description: 'TestimonialsConstant/des-04',
  fullName: 'Michael',
  job: 'TestimonialsConstant/job-04',
  image: 'https://cdn.pinkswan.ch/application/LOUISEBOT/23080613503322_1.jpg',
 },
];
