import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

const Section11 = ({ t, params }: { t: TFunction<'translation', undefined>; params: any }) => {
  const { i18n } = useTranslation();
  return (
    <div className="tw-w-full tw-relative tw-flex tw-items-start xl:tw-h-[650px] tw-h-full tw-justify-center tw-bg-gray-800 tw-pt-5 xl:tw-pt-0">
      <div className="tw-origin-top-left tw-w-80 tw-h-[510px] tw-absolute tw-left-0 tw-bottom-[-10px] tw-hidden xl:tw-block">
        <img src="https://cdn.pinkswan.ch/application/LOUISEBOT/24071407522722_Group_2610662.png" alt="Video chatbot" />
      </div>
      <div className="tw-self-stretch tw-h-full xl:tw-mt-20 tw-mb-20 xl:tw-mb-0 tw-flex-col tw-justify-start tw-items-center tw-gap-[52px] tw-flex">
        <div className="tw-self-stretch tw-h-full xl:tw-h-[274px] tw-flex-col tw-justify-start tw-items-center tw-gap-8 tw-flex">
          <div className="tw-self-stretch tw-h-full xl:tw-h-[200px] tw-flex-col tw-justify-start tw-items-center tw-gap-6 tw-flex">
            <div className="tw-pl-1 tw-pr-4 tw-py-1 tw-bg-gray-900 tw-rounded-[14px] tw-justify-start tw-items-center tw-gap-4 tw-inline-flex">
              <div className="tw-px-3 tw-py-0.5 tw-bg-blue-600 tw-rounded-[10px] tw-justify-start tw-items-center tw-flex">
                <span className="tw-text-zinc-300 tw-text-sm tw-font-semibold tw-font-['Lexend'] tw-leading-tight">
                  {t('Home/Section11/key1')}
                </span>
              </div>
              <div className="tw-justify-start tw-items-center tw-gap-2 tw-flex">
                <span className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                  {t('Home/Section11/key2')}
                </span>
              </div>
            </div>
            <div className="tw-self-stretch tw-h-full xl:tw-h-36 tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-flex">
              <h1 className="xl:tw-w-[624px] tw-w-full tw-text-center tw-flex tw-flex-col">
                <div>
                  <span className="tw-text-gray-50 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                    {t('Home/Section11/key3-1')}
                  </span>{' '}
                  <span className="tw-text-yellow-400 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                    {t('Home/Section11/key3-2')}
                  </span>{' '}
                </div>
                <div>
                  <span className="tw-text-gray-50 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                    {t('Home/Section11/key3-3')}
                  </span>{' '}
                  <span className="tw-text-green-500 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                    {t('Home/Section11/key3-4')}
                  </span>
                </div>
                <div>
                  <span className="tw-text-gray-50 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                    {t('Home/Section11/key3-5')}
                  </span>
                </div>
              </h1>
            </div>
          </div>
          <div className="tw-px-6 tw-py-[9px] tw-bg-blue-600 tw-rounded-[99px] tw-shadow tw-justify-center tw-mt-4 tw-items-center tw-inline-flex">
            <a
              href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${
                params?.lang ? params?.lang : 'en-US'
              }/register`}
              className="tw-text-white tw-text-base tw-font-semibold tw-font-['Figtree'] tw-leading-normal "
            >
              {t('Home/Section11/key4')}
            </a>
          </div>
        </div>
        <div className="tw-self-stretch tw-h-full xl:tw-h-[108px] tw-flex-col tw-justify-start tw-items-center tw-gap-6 tw-flex">
          <div className="tw-justify-start tw-items-center tw-gap-2 tw-inline-flex">
            <div className="tw-w-[66px] tw-text-center tw-text-green-500 tw-text-2xl tw-font-extrabold tw-font-['Sofia Sans'] tw-leading-[19px]">
              40%
            </div>
            <span className="tw-text-gray-50 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
              {t('Home/Section11/key5')}
            </span>
          </div>
          <div className="tw-justify-start tw-items-center tw-gap-2 tw-inline-flex">
            <div className="tw-w-[66px] tw-text-center tw-text-pink-500 tw-text-2xl tw-font-extrabold tw-font-['Sofia Sans'] tw-leading-[19px]">
              24/7
            </div>
            <span className="tw-text-gray-50 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
              {t('Home/Section11/key6')}
            </span>
          </div>
          <div className="tw-justify-start tw-items-center tw-gap-2 tw-inline-flex">
            <div className="tw-text-center tw-text-blue-500 tw-text-2xl tw-font-extrabold tw-font-['Sofia Sans'] tw-leading-[19px]">
              🚀
            </div>
            <span className="tw-text-gray-50 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
              {t('Home/Section11/key7')}
            </span>
          </div>
        </div>
      </div>
      <div className="tw-origin-top-left tw-w-80 tw-h-[510px] tw-absolute tw-right-0 tw-bottom-[-10px] tw-hidden xl:tw-block">
        <img src="https://cdn.pinkswan.ch/application/LOUISEBOT/24071510273430_Group_2610663.png" alt="VIDEO POP-UPS" />
      </div>
    </div>
  );
};

export default Section11;
