import { TFunction } from 'i18next';
import { useParams } from 'next/navigation';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BiTachometer, BiTrip, BiUserVoice } from 'react-icons/bi';

const Section3 = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const params = useParams();

  const items = [
    {
      iconicText: t('Home/Section3/key1').toString(),
      title: t('Home/Section3/key1-2').toString(),
      description: t('Home/Section3/key1-3').toString(),
    },
    {
      iconicText: t('Home/Section3/key2').toString(),
      title: t('Home/Section3/key2-2').toString(),
      description: t('Home/Section3/key2-3').toString(),
    },
    {
      iconicText: t('Home/Section3/key3').toString(),
      title: t('Home/Section3/key3-2').toString(),
      description: t('Home/Section3/key3-3').toString(),
    },
  ];

  return (
    <section className="sec-3">
      <Container fluid>
        <h2>{t('Home/Section3/key0').toString()}</h2>
        <Row className="g-3 justify-content-center">
          {items.map((item, index) => (
            <Col lg={4} md={6} sm={6} xs={12} key={index}>
              <div className="box">
                <div className="icon-wrapper">{item.iconicText}</div>
                <h3 className={`${params.lang === 'fr-FR' ? 'tw-w-[70%]' : 'tw-w-[60%]'}`}>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      {/* <div className="xl:tw-max-w-[1300px] tw-mx-auto tw-flex md:tw-flex-row tw-flex-col tw-gap-2">
        <div className="tw-basis-1/3">
          <div
            className={`bg-tenColor rounded-4 ${
              params.lang === 'en-US' ? 'md:tw-min-h-[312px]' : 'md:tw-min-h-[360px]'
            } md:tw-p-8 tw-p-5`}
          >
            <span className="features-icon">
              <BiTachometer className="fs-1 color-thirdColor" />
            </span>
            <h2 className="font-sofiaSans tw-mt-3">{t('home/SiteFeatures/title-01')}</h2>
            <p className="font-sofiaSans fs-5">{t('home/SiteFeatures/text-01')}</p>
          </div>
        </div>
        <div className="tw-basis-1/3">
          <div
            className={`bg-tenColor rounded-4 ${
              params.lang === 'en-US' ? 'md:tw-min-h-[312px]' : 'md:tw-min-h-[360px]'
            } md:tw-p-8 tw-p-5`}
          >
            <span className="features-icon">
              <BiUserVoice className="fs-1 color-thirdColor" />
            </span>
            <h2 className="font-sofiaSans tw-mt-3">{t('home/SiteFeatures/title-02')}</h2>
            <p className="font-sofiaSans fs-5">{t('home/SiteFeatures/text-02')}</p>
          </div>
        </div>
        <div className="tw-basis-1/3">
          <div
            className={`bg-tenColor rounded-4 ${
              params.lang === 'en-US' ? 'md:tw-min-h-[312px]' : 'md:tw-min-h-[360px]'
            } md:tw-p-8 tw-p-5`}
          >
            <span className="features-icon">
              <BiTrip className="fs-1 color-thirdColor" />
            </span>
            <h2 className="font-sofiaSans tw-mt-3">{t('home/SiteFeatures/title-03')}</h2>
            <p className="font-sofiaSans fs-5">{t('home/SiteFeatures/text-03')}</p>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Section3;
