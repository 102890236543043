'use client';
import Script from 'next/script';
import React, { useEffect } from 'react';

type Props = {
  language: string;
};

export default function HomeWidget({ language }: Props) {
  useEffect(() => {
    return () => {
      const element = document.getElementById('ps_w');
      element?.remove();
    };
  }, []);

  return (
    <>
      {language == 'en-US' && (
        <Script>
          {`
      !(function () { function g() {
    var script = document.createElement('script'), code = 'e55e08b0-4a75-444f-9117-2b659ff24bbf',url = 'https://cdn.pinkswan.ch/application/LOUISEBOT/23082315255659_LouiseBot_widget.js', l = localStorage.setItem('ps_widget' , code);
    (script.type = 'text/javascript'),(script.async = !0),(script.src = url);
    document.getElementsByTagName('head')[0].appendChild(script);
  }
  document.readyState === 'complete' ? g() : window.attachEvent ? window.attachEvent('onload', g)
  : window.addEventListener('load', g, !1);
  })();
    `}
        </Script>
      )}

      {language == 'fr-FR' && (
        <Script>
          {`
  !(function () { function g() {
    var script = document.createElement('script'), code = 'e55e08b0-4a75-444f-9117-2b659ff24bbf',url = 'https://cdn.pinkswan.ch/application/LOUISEBOT/23082315255659_LouiseBot_widget.js', l = localStorage.setItem('ps_widget' , code);
    (script.type = 'text/javascript'),(script.async = !0),(script.src = url);
    document.getElementsByTagName('head')[0].appendChild(script);
  }
  document.readyState === 'complete' ? g() : window.attachEvent ? window.attachEvent('onload', g)
  : window.addEventListener('load', g, !1);
  })();
    `}
        </Script>
      )}
    </>
  );
}
